import { display, fontSize } from '@mui/system';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import theme from '../../../theme';
const Spacer = () => {
    return <span style={{
        display: 'inline-block',
        margin: '0 5px',
    }}>/</span>
}

const Breadcrumb = ({ paths,currentFile }) => {
    const navigator = useNavigate();

    return (
        <div className="breadcrumb">
             <Link key={0} className='item' to={`/user/netstorage/0`} >/</Link>
            {paths.map((folder, index) => {
                return (
                <Link  key={folder.id} className='item' to={`/user/netstorage/${folder.id}`} >{folder.name} /</Link>
                );
            })}
            {currentFile &&  (<span 
                    className='item' 
                    style={{
                        fontSize:"0.8rem",
                        color:"gray",
                        textDecoration:"none"
                    }}>{currentFile.name}</span>
            ) }
    </div>
    );
}

export default Breadcrumb;