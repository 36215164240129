import React, { useCallback, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Switch,
    TextField,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    Typography,
    CircularProgress,
    Grid,
    Divider,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import http from '../../../http'
import Empty from '../../../components/Empty';
import { inject, observer } from 'mobx-react';
import {getShareInfo} from '../../../util'

const ShareForm = inject('storageStore')(observer(({ storageStore }) => {
    const { t } = useTranslation();
    const [expiration, setExpiration] = useState(7);
    const [passwordType, setPasswordType] = useState('random');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    let folderId = storageStore.currentShareFolder.id;

    const generateCode = () => {
        let code = Math.random() * 10000
        code = code.toFixed(0)
        if (code.length < 4) {
            code = code.padStart(4, '0')
        }
        return code
    }

    const passwordRegex = /^[A-Za-z0-9]{4}$/;
    const validatePassword = (password) => {
        if (!password || !passwordRegex.test(password)) {
            return t('Code must be 4 numbers')
        }
        return '';
    }
    const saveShare = () => {
        const newPassword = passwordType === 'random' ? generateCode() : password
        if (validatePassword(newPassword)) {
            setPasswordError(true)
            return;
        }
        http.post(`/user/netstorage/share/${folderId}`,
            { password: newPassword, expiration: expiration }
        ).then(orderResponse => {
            let share = orderResponse.data.share
            storageStore.saveShareInfo(folderId, share)
        })

    };

  
    return (
        <Box>
            <FormControl component="fieldset" sx={{ display: 'flex', flexGrow: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: 'center' }}>
                <FormLabel component="span" sx={{ width: 80 }} >{t("Expire")}</FormLabel>
                <RadioGroup
                    aria-label="options"
                    name="expire"
                    value={expiration}
                    row={true}
                    onChange={(e) => { setExpiration(e.target.value) }}

                >
                    <FormControlLabel value="7" control={<Radio />} label={t('7 days')} />
                    <FormControlLabel value="14" control={<Radio />} label={t('14 days')} />
                    <FormControlLabel value="30" control={<Radio />} label={t('30 days')} />
                </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" sx={{ display: 'flex', flexGrow: 1, flexDirection: "row", justifyContent: "center", alignItems: 'flex-start' }}>
                <FormLabel component="span" sx={{ width: 80, marginTop: "10px" }} >{t("Code")}</FormLabel>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: 'flex-start' }}>
                    <FormControlLabel
                        value="random"
                        checked={passwordType === 'random'}
                        control={<Radio />}
                        label={t('random')}
                        labelPlacement="end"
                        onChange={(e) => {
                            const value = e.target.value
                            setPasswordType(value)
                            //generateCode()
                        }}
                    />
                    <Box sx={{ marginLeft: "-11px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Radio
                            checked={passwordType === 'manul'}
                            value="manul"
                            onChange={(e) => {
                                setPasswordType(e.target.value)
                            }}
                        />
                        <TextField
                            size='small'
                            id="password"
                            value={password}
                            error={!!passwordError}
                            onChange={(e) => {
                                const value = e.target.value
                                if (value.length <= 4) {
                                    setPassword(value)
                                    setPasswordError(validatePassword(value));
                                }

                            }}
                            disabled={passwordType === 'random'}
                            helperText={passwordError}
                        />
                        <Typography
                            sx={{ ml: 1 }}
                            variant="body2"
                        >{t("Code must be 4 numbers")}</Typography>
                    </Box>
                </Box>
            </FormControl>
            <Box sx={{ mt:3, textAlign: 'center' }}>
                <Button onClick={saveShare} variant="contained" color="primary">
                    {t('Save Share')}
                </Button>
            </Box>
        </Box>
    )
}));

const Shared = inject('storageStore','snackbarStore')(observer(({ storageStore, snackbarStore }) => {
    const { t } = useTranslation();
    const [url, setUrl] = useState('');
    const [autoFill, setAutoFill] = useState(false);
    const [folderId, setFolderId] = useState(0);
    // Initialize shareInfo with default values for all properties used in rendering
    const [shareInfo, setShareInfo] = useState({
        id: '',
        password: '',
        expiration: 0,
        expiredTime: ''
    });
    const [qrCodeData, setQrCodeData] = useState(null);
    const [loadingQR, setLoadingQR] = useState(false);
    
    const cancelShare = () => {
        http.delete(`/user/netstorage/share/${folderId}`)
            .then(orderResponse => {
                if (orderResponse.data.success) {
                    storageStore.saveShareInfo(folderId, null)
                }
            })
    }

    const copyToClipboard = useCallback(async () => {
        try {
            // 使用 Clipboard API
            let totalText = ""
            if( !autoFill ){
                totalText += `${t('Link')}: ${url}  ${t('Code')}: ${shareInfo.password}`
            }else{
                totalText = url
            }
            await navigator.clipboard.writeText(totalText);
            snackbarStore.showSnackbar(t('Text copied to clipboard'))
        } catch (err) {
            snackbarStore.showSnackbar(t('Failed to copy text: '), 'error')
        }
    },[shareInfo,autoFill,url])

    const getQRCode = useCallback(async () => {
        if (!shareInfo || !shareInfo.id) return;
        
        setLoadingQR(true);
        try {
            const response = await http.get(`/user/netstorage/share/${shareInfo.id}/qrCode`);
            if (response.data && response.data.imageData) {
                setQrCodeData(response.data.imageData);
            } else {
                snackbarStore.showSnackbar(t('Failed to get QR code'), 'error');
            }
        } catch (error) {
            console.error('Error getting QR code:', error);
            snackbarStore.showSnackbar(t('Error fetching QR code'), 'error');
        } finally {
            setLoadingQR(false);
        }
    }, [shareInfo, snackbarStore, t]);

    useEffect(() => {
        let folderId = storageStore.currentShareFolder.id;
        setFolderId(folderId)
        let shareInfo = storageStore.currentShareFolder.Shares[0]
        setShareInfo(shareInfo)
        const baseUrl = `${window.location.origin}/s/${shareInfo.id}`;
        if( autoFill ){
          setUrl(`${baseUrl}?pwd=${shareInfo.password}`)
        }else{
          setUrl(baseUrl)
        }
        
        // Automatically fetch QR code when the component mounts and share info is available
        if (shareInfo && shareInfo.id) {
            getQRCode();
        }
    }, [autoFill, storageStore.currentShareFolder, getQRCode]);

    return (
        <Box>
            <Grid container spacing={2}>
                {/* Left side - Form information */}
                <Grid item xs={12} md={6}>
                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                            <Typography>{t("Expire")}{shareInfo.expiration}{t("days")}</Typography>
                            <Typography sx={{ ml: 1 }}>{getShareInfo(shareInfo.expiredTime)}</Typography>
                        </Box>
                        <Box sx={{ display:"flex", flexDirection:"column" }}>
                            <FormControl sx={{ mt: 2, minWidth:250 }}>
                                <FormLabel>{t("Shared Link")}</FormLabel>
                                <TextField
                                    placeholder={t("Shared Link")}
                                    value={url}
                                    size="small"
                                    multiline={true}
                                />
                            </FormControl>
                            <FormControl component="fieldset" sx={{ display: 'flex', flexGrow: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: 'center' }}>
                                <FormControl sx={{ mt: 2 }}>
                                    <TextField
                                        size='small'
                                        label={t("Code")}
                                        value={shareInfo.password}
                                        disabled={true}
                                        sx={{ width: 100 }}
                                    />
                                </FormControl>
                            </FormControl>
                            <FormControlLabel
                                sx={{ mt: 2 }}
                                name='autofill'
                                control={<Switch checked={autoFill} onChange={(e) => { setAutoFill(e.target.checked) }} />}
                                label={t("Auto fill password")}
                            />
                            <Button sx={{ mt: 1, width: 200 }} variant="outlined" color="secondary" onClick={copyToClipboard}>
                                {t('Copy url and password to clipboard')}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                
                {/* Right side - QR Code */}
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>{t('Mini-Program QR Code')}</Typography>
                        {loadingQR ? (
                            <CircularProgress />
                        ) : qrCodeData ? (
                            <img 
                                src={`data:image/png;base64,${qrCodeData}`} 
                                alt="QR Code" 
                                style={{ maxWidth: '200px', maxHeight: '200px' }} 
                            />
                        ) : (
                            <Typography color="text.secondary">{t('Failed to load QR code')}</Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
            
            {/* Action button at the bottom */}
            <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Button onClick={cancelShare} variant="contained" color="primary">
                    {t('Cancel Share')}
                </Button>
            </Box>
        </Box>
    );
}));

const Share = inject('storageStore')(observer(({ storageStore }) => {
    const { t } = useTranslation();
   
    if (!storageStore.currentShareFolder) return null;

    const onClose  = () => {
        storageStore.setCurrentShareFolder(null);
    }

    return (
        <Dialog 
            open={!!storageStore.currentShareFolder} 
            disableEscapeKeyDown
            maxWidth="sm"
            fullWidth={true}
            onClose={() => {}}  // Empty function to prevent default close behavior
        >
            <DialogTitle>
                {t("Share")}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {storageStore.currentShareFolder?.Shares.length>0 ? 
                <Shared /> : 
                <ShareForm />}
            </DialogContent>
        </Dialog>
    );
}));

export default Share;