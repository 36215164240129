import http from '../http';

import { observable, action, makeObservable, runInAction, computed } from "mobx";
import axios from 'axios';
import { v4 as v4 } from 'uuid';

const newFile = (fileSize, isFolder, name, parentId, url) => {
    return {
        fileSize,
        id:v4(),
        isFolder,
        name,
        parentId,
        urlSmall:url,
        progress:0
    };
};

const resizeImage = async (blob, maxWidth) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(blob);
        img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;
            
            if (width > maxWidth) {
                height = (maxWidth * height) / width;
                width = maxWidth;
            }
            
            canvas.width = width;
            canvas.height = height;
            
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            
            canvas.toBlob((resizedBlob) => {
                URL.revokeObjectURL(img.src);
                resolve(resizedBlob);
            });
        };
    });
};

class StorageStore {
    files = [];
    breadcrumbs = [];
    currentFolderId = 0;
    currentFile = 0;
    parentFiles = [];
    currentShareFolder = null;

    constructor() {
        makeObservable(this, {
            files: observable,
            currentFolderId: observable,
            currentFile: observable,
            parentFiles: observable,
            currentShareFolder: observable,
            createFolder: action,
        });
    }

    setCurrentFolderId = (folderId) => {
        if (!folderId) {
            folderId = 0;
        }
        this.currentFolderId = folderId;
    }

    setCurrentShareFolder = (folder) => {
        if (folder) {
            // Ensure folder has a Shares array property
            this.currentShareFolder = {
                ...folder,
                Shares: Array.isArray(folder.Shares) ? folder.Shares : []
            };
        } else {
            this.currentShareFolder = null;
        }
    }

    saveShareInfo = (fileId, shareInfo) => {
        const file = this.files.find(f => f.id === fileId);
        if (!file) return;
        
        // Ensure file has a Shares property that is an array
        if (!Array.isArray(file.Shares)) {
            file.Shares = [];
        }
        
        if (shareInfo) {
            file.Shares = [shareInfo];
        } else {
            file.Shares = [];
        }
        // Update currentShareFolder if this is the same file
        if (this.currentShareFolder && this.currentShareFolder.id === fileId) {
            this.currentShareFolder = {
                ...this.currentShareFolder,
                Shares: file.Shares
            };
        }
    }

    rename = async (fileId, newName) => {
        let response = await http.put(`/user/netstorage/file/${fileId}`, { name: newName });
        this.featchFiles();
    }

    featchFiles = async () => {
        let folderId = this.currentFolderId;
        let response = await http.get(`/user/netstorage/files/${folderId}`);
        this.files = response.data.files;
        this.currentFile = response.data.currentFile;
        this.parentFiles = response.data.parentFiles;
    }

    deleteFile = async (fileId) => {
        let response = await http.delete(`/user/netstorage/file/${fileId}`);
        this.featchFiles();
    }

    addFile = async (file) => {
        let fileName = file.name;
        let fileSize = file.size;
        let parentId = this.currentFolderId;
        let blob = new Blob([file]);
        let resizedThubnailBlob = await resizeImage(blob, 200);
        let localUrl = URL.createObjectURL(resizedThubnailBlob);
        let aNewFile = newFile(fileSize,  false, fileName, parentId, localUrl)
        this.files.push(aNewFile);
        let resizedImageBlob = await resizeImage(blob, 1920);
        let resizedImageSize = resizedImageBlob.size;
        let resizedThubnailSize = resizedThubnailBlob.size;
        let response = await http.post('/user/netstorage/requestUrl', { 
            name: fileName,
            fileSize:resizedImageBlob.size,
            fileSmallSize:resizedThubnailBlob.size,
            parentId:parentId
         });
        let {url,urlSmall,path,pathSmall} = response.data;
        let fileType = file.type;

    
        await axios.put(url, resizedImageBlob, {
            headers: {
              'Content-Type': fileType
            },
        });
        await axios.put(urlSmall, resizedThubnailBlob, {
            headers: {
              'Content-Type': fileType
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.updateProgress(aNewFile.id, percentCompleted);
            },
        });
        let addFileResponse = await http.post('/user/netstorage/file', { 
            name: fileName,
            parentId:parentId,
            fileSize:fileSize,
            path:path,
            pathSmall:pathSmall
        });
        this.featchFiles();
    }

    updateProgress(uuid, progress) {
        const file = this.files.find(f => f.id === uuid);
        if (file) {
            file.progress = progress;
        }
    }

    createFolder = async (folderName) => {
        let parentId = this.currentFolderId;
        http.post('/user/netstorage/folder', { name: folderName,parentId }).then((res) => {
            this.featchFiles();
        }).catch((err) => {
            console.error(err);
        });
    }
   
}

const storageStore = new StorageStore();

export { storageStore }
