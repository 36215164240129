import FolderIcon from '@mui/icons-material/Folder';
import { Alert, Box, Button, Dialog, FormLabel, Grid, IconButton, Tab, Slide, TextField, Typography, CircularProgress } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import CustomTabLabel from '../../components/CustomTabLabel';
import DownloadButton from '../../components/DownloadButton';
import Empty from '../../components/Empty';
import http from '../../http';
import { CustomerImageGallery } from './CustomerImageGallery';
import ReplyIcon from '@mui/icons-material/Reply';
import { Close, NavigateBefore, NavigateNext, Download } from '@mui/icons-material';
import { useSwipeable } from 'react-swipeable';
import theme from '../../theme';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';

const SharedFolderPassword = inject('anonymousUserStore')(observer(({ anonymousUserStore, shareId, pwd, onSubmitPassword }) => {
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const { t } = useTranslation();
    const [inputPassword, setInputPassword] = useState("");

    useEffect(() => {
        // 如果URL中包含密码参数，尝试使用它
        if (pwd) {
            setInputPassword(pwd);
            onSubmitPassword(pwd);
        }
    }, []); // Only run once on mount

    const handleSubmit = useCallback(async (event) => {
        event?.preventDefault();
        if (!inputPassword) {
            setError(true);
            setErrorMessage(t("code cannot be empty"));
            return;
        }
        
        // Let parent component handle the verification
        onSubmitPassword(inputPassword);
        // Don't store password here - only store if verification succeeds
    }, [inputPassword, onSubmitPassword, t]);

    return (
        <Dialog 
        fullScreen
        open={true}
        keepMounted
        disablebackdropclick="true"
        PaperProps={{
          style: {
            backgroundColor: 'white',
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          },
        }}>
            <Box sx={{ p: 10 }}>
                <form 
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"center",
                        alignItems:"center"
                    }}
                    onSubmit={handleSubmit}>
                    <FolderIcon style={{ fontSize: 100, color:"#f7d577" }} />
                    <Box sx={{ 
                        mt: 3,
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"center",
                        width:"100%",
                        maxWidth:"240px"
                    }}>
                        <FormLabel>{t("Code")}</FormLabel>
                        <TextField
                            error={!!error}
                            placeholder={t('Code')}
                            value={inputPassword}
                            helperText={error ? errorMessage : ''}
                            onChange={(e) => {
                                setInputPassword(e.target.value);
                                setError(false);
                                setErrorMessage(null);
                            }}
                            size='small'
                            sx={{
                                minWidth: 120
                            }}
                        />
                         <Button 
                            sx={{
                                mt:1
                            }}
                            variant="contained" 
                            color="primary"
                             type="submit"
                        >
                            {t('Enter Code')}
                        </Button>
                        {error && <Typography color="error" sx={{ mt: 1 }}>{errorMessage}</Typography>}
                    </Box>
                </form>
            </Box>
        </Dialog>
    )
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FilePreviewDialog = inject('snackbarStore')(observer(({
    open,
    setOpen,
    currentPreviewIndex,
    setCurrentPreviewIndex,
    previewFiles,
    snackbarStore,
  }) => {
  
    const [animation, setAnimation] = useState(false);

    const { t } = useTranslation();

    const handleClose = (event, reason) => {
        setAnimation(false)
        setOpen(false)
    };

    const handleNext = useCallback(() => {
        const lastIndex = previewFiles.length;
        if (currentPreviewIndex < lastIndex - 1) {
            setCurrentPreviewIndex(currentPreviewIndex + 1);
        } else {
        snackbarStore.showSnackbar(t('Last picture'), 'warning')
        }
    },[previewFiles,currentPreviewIndex,snackbarStore,t]);

    const handlePrev =useCallback( () => {
        if (currentPreviewIndex > 0) {
            setCurrentPreviewIndex(currentPreviewIndex - 1);
        } else {
        snackbarStore.showSnackbar(t('Already the first picture'), 'warning')
        }
    },[previewFiles,currentPreviewIndex,snackbarStore,t]);

    // 添加下载当前图片的功能
    const handleDownloadImage = useCallback(() => {
      if (!previewFiles || previewFiles.length === 0 || currentPreviewIndex < 0) {
        return;
      }
      
      const currentImage = previewFiles[currentPreviewIndex];
      if (!currentImage || !currentImage.url) {
        snackbarStore.showSnackbar(t("Unable to download image"), "error");
        return;
      }
      
      // 创建一个隐形的a标签来下载图片
      const link = document.createElement('a');
      link.href = currentImage.url;
      link.download = currentImage.name || `image_${currentPreviewIndex + 1}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      snackbarStore.showSnackbar(t("Image download started"), "success");
    }, [previewFiles, currentPreviewIndex, snackbarStore, t]);

    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
    });

    const imageStyle = {
        height: "60px",
        margin: "10px",
        border: "3px solid #fff",
        cursor: "pointer",
    }


    useEffect(() => {
        function handleKeyPress(event) {
        switch (event.key) {
            case 'ArrowLeft':
            handlePrev()
            break;
            case 'ArrowRight':
            handleNext()
            break;
            default:
            // 处理其他按键
            break;
        }
        event.preventDefault();
        event.stopPropagation();
        }
        if(open){
        window.addEventListener('keydown', handleKeyPress);
        }

        // 组件卸载时移除事件监听器
        return () => {
        window.removeEventListener('keydown', handleKeyPress);
        };
    }, [open,handlePrev,handleNext]);


    if (!open) {
        return null
    }

    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            keepMounted
            disablebackdropclick="true"
            maxWidth={false}
            onLoad={() => setAnimation(true)}
            PaperProps={{
                style: {
                    backgroundColor: 'white',
                    height: "100%",
                    display: "flex"
                },
            }}
        >

        <Box sx={{ height: "60px", zIndex: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <IconButton onClick={handleClose} sx={{ width: "60px", color: theme.palette.primary.main }}>
            <ArrowBackIosIcon />
            </IconButton>
            <Typography sx={{ flex: 1, marginRight: "60px", textAlign: "center", fontSize: "1rem" }}>{t("Picture Preview")}</Typography>
            
            {/* 添加下载按钮 */}
            <IconButton 
                onClick={handleDownloadImage}
                sx={{ 
                    width: "60px", 
                    color: theme.palette.primary.main, 
                    position: "absolute",
                    right: 0,
                    marginRight: "10px"
                }}
                disabled={!previewFiles || previewFiles.length === 0}
            >
                <Download />
            </IconButton>
        </Box>


        <Box
            {...handlers}
            sx={{ flex: 1, display: "flex", flexDirection: "row", width: "100%" }}>
            <Box sx={{
            display: {
                xs: "none",
                md: "flex"
            },
            alignItems: "center", justifyContent: "center", width: 100
            }}>
            <IconButton onClick={handlePrev} sx={{ color: '#fff', backgroundColor: theme.palette.primary.light }}>
                <NavigateBefore />
            </IconButton>
            </Box>

            <Box
            style={{ 
                height:"100%", 
                width: "100%", 
                overflow: "hidden"
            }}
            >
            <Box
                style={{
                height: "100%",
                width: "100%", 
                transition: animation ? "transform 0.3s ease-in-out" : "",
                transform: `translateX(-${currentPreviewIndex * 100}%)`
                }}
            >
                {previewFiles?.map((file, index) => {
                if( currentPreviewIndex <= index + 1 && currentPreviewIndex >= index - 1){
                    return <Box key={index}
                    sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    left: 0,
                    top: "50%",
                    height: "100%",
                    width: "100%",
                    transform: `translate(${index * 100}% ,-50%)`,
                    backgroundColor: "black"
                    }}>
                    {<img alt={`slide-${index}`} src={currentPreviewIndex <= index + 1 && currentPreviewIndex >= index - 1 ? file?.url : defaultPicture} style={{ width: "100%", height: "100%", objectFit: "contain", objectPosition: "center" }} />}
                    
                    </Box>
                }else{
                    return null
                }
                })}
                {previewFiles.length === 0 && <Empty message={t("No picture available.")} />}
            </Box>
            </Box>

            <Box sx={{
            display: {
                xs: "none",
                md: "flex"
            }, alignItems: "center", justifyContent: "center", width: 100
            }}>
            <IconButton onClick={handleNext} color='primary' sx={{ color: '#fff', backgroundColor: theme.palette.primary.light }}>
                <NavigateNext />
            </IconButton>
            </Box>
        </Box>

        </Dialog>
    );
}));
  


const PackageButton = inject('snackbarStore')(observer(({ 
    packagingStatus, 
    downloadUrl, 
    packImage, 
    snackbarStore 
}) => {
    const { t } = useTranslation();
    
    const downloadPackage = useCallback(() => {
        if (downloadUrl) {
            window.open(downloadUrl, '_blank');
        } else {
            snackbarStore.showSnackbar(t("Download link not available"), "error");
        }
    }, [downloadUrl, snackbarStore, t]);
    
    switch (packagingStatus) {
        case 'packaging':
            return (
                <Button
                    disabled
                    startIcon={<CircularProgress size={20} />}
                >
                    {t("Packaging...")}
                </Button>
            );
        case 'packaged':
            return (
                <Button
                    onClick={downloadPackage}
                    startIcon={<Download />}
                    color="success"
                >
                    {t("Download Package")}
                </Button>
            );
        case 'failed':
            return (
                <Button
                    onClick={packImage}
                    color="error"
                >
                    {t("Retry Pack")}
                </Button>
            );
        default:
            return (
                <Button onClick={packImage}>
                    {t("Pack images")}
                </Button>
            );
    }
}));

// 修改下载到本地文件夹组件
const SaveToLocalButton = inject('snackbarStore')(observer(({ 
  files, 
  currentFolder, 
  snackbarStore,
  password,
  shareId
}) => {
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  
  const handleSaveToLocal = async () => {
    // 检查浏览器支持
    if (!window.showDirectoryPicker) {
      snackbarStore.showSnackbar(t("Your browser doesn't support folder selection. Please use Chrome or Edge."), "error");
      return;
    }
    
    try {
      setSaving(true);
      
      // 让用户选择文件夹
      const directoryHandle = await window.showDirectoryPicker();
      
      // 创建一个"图派下载"或"picsect"的子文件夹
      let baseFolder;
      try {
        // 尝试创建中文名文件夹，如果不支持则使用英文
        try {
          baseFolder = await directoryHandle.getDirectoryHandle("图派下载", { create: true });
        } catch (e) {
          baseFolder = await directoryHandle.getDirectoryHandle("picsect", { create: true });
        }
      } catch (error) {
        console.error("Error creating base folder:", error);
        snackbarStore.showSnackbar(t("Error creating base folder"), "error");
        // 如果无法创建子文件夹，使用用户选择的文件夹作为基础文件夹
        baseFolder = directoryHandle;
      }
      
      // 创建一个递归函数来保存文件夹结构
      const saveFolder = async (parentFolderId, parentDirectoryHandle) => {
        // 获取当前文件夹下的所有文件
        const folderItems = files.filter(file => file.parentId === parentFolderId);
        
        for (const item of folderItems) {
          if (item.isFolder) {
            // 是文件夹，则创建新文件夹并递归处理
            try {
              const newFolderHandle = await parentDirectoryHandle.getDirectoryHandle(
                item.name || "folder_" + item.id, { create: true }
              );
              await saveFolder(item.id, newFolderHandle);
            } catch (error) {
              console.error("Error creating folder:", error);
              snackbarStore.showSnackbar(t("Error creating folder") + ": " + item.name, "error");
            }
          } else {
            // 是文件，下载并保存
            try {
              // 获取文件内容
                const response = await fetch(item.url);

                if (!response.ok) throw new Error("Failed to fetch file");

                const blob = await response.blob();

                // 生成文件名 (使用原始名称或生成一个)
                const fileName = (item.name || `image_${item.id}.jpg`).replace(/\s+/g, '');

                // 创建并写入文件
                const fileHandle = await parentDirectoryHandle.getFileHandle(fileName, { create: true });
                const writable = await fileHandle.createWritable();
                await writable.write(blob);
                await writable.close();
            } catch (error) {
              console.error("Error saving file:", error);
              snackbarStore.showSnackbar(t("Error saving file") + ": " + (item.name || item.id), "error");
            }
          }
        }
      };
      
      // 开始保存当前文件夹，使用我们创建的基础文件夹
      await saveFolder(currentFolder, baseFolder);
      
      snackbarStore.showSnackbar(t("Files saved successfully!"), "success");
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("User cancelled the operation");
      } else {
        console.error("Error saving to local folder:", error);
        snackbarStore.showSnackbar(t("Error saving to local folder"), "error");
      }
    } finally {
      setSaving(false);
    }
  };
  
  return (
    <Button
      disabled={saving}
      onClick={handleSaveToLocal}
      startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
      sx={{ ml: 1 }}
    >
      {saving ? t("Saving...") : t("Save to folder")}
    </Button>
  );
}));

const SharedFolder = inject('snackbarStore', 'anonymousUserStore')(observer(({ anonymousUserStore, snackbarStore }) => {
    const { shareId } = useParams();
    const [searchParams] = useSearchParams();
    const pwd = searchParams.get('pwd');
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(null);
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [files, setFiles] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [parentFolder, setParentFolder] = useState(null);
    const [rootFolderId, setRootFolderId] = useState(null);
    const [previewFiles, setPreviewFiles] = useState([]);
    const [currentPreviewIndex, setCurrentPreviewIndex] = useState(-1);
    const [packagingStatus, setPackagingStatus] = useState('not_packaged');
    const [downloadUrl, setDownloadUrl] = useState(null);
    const pollingIntervalRef = useRef(null);
  
    const { t } = useTranslation();

    const checkPackageStatus = useCallback((currentPassword) => {
        if (!currentPassword) return;
        
        http.get(`/customer/share/${shareId}/packStatus`, {
            params: { password: currentPassword }
        })
        .then(response => {
            setPackagingStatus(response.data.status);
            if (response.data.status === 'packaged' && response.data.downloadUrl) {
                setDownloadUrl(response.data.downloadUrl);
                // Stop polling if packaging is complete
                if (pollingIntervalRef.current) {
                    clearInterval(pollingIntervalRef.current);
                    pollingIntervalRef.current = null;
                }
            } else if (response.data.status === 'failed') {
                snackbarStore.showSnackbar(t("Package creation failed"), "error");
                // Stop polling if packaging failed
                if (pollingIntervalRef.current) {
                    clearInterval(pollingIntervalRef.current);
                    pollingIntervalRef.current = null;
                }
            }
        })
        .catch(error => {
            console.error("Error checking package status:", error);
        });
    }, [shareId, snackbarStore, t]);

    // This is the only place we attempt to load data with a password
    const tryLoadWithPassword = useCallback((passwordToTry) => {
        if (!passwordToTry) return;
        
        http.get(`/customer/share/${shareId}`, {
            params: { password: passwordToTry }
        })
        .then(response => {
            // Password is valid
            setFiles(response.data.files);
            setCurrentFolder(response.data.share.fileId);
            setRootFolderId(response.data.share.fileId);
            
            // Store valid password in state only (not localStorage)
            setPassword(passwordToTry);
            
            // Hide password form
            setShowPasswordForm(false);
            
            // Check package status
            checkPackageStatus(passwordToTry);
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                // Password is invalid
                setPassword("");
                setShowPasswordForm(true);
                setPasswordError(t("Invalid code. Please try again."));
            }
        });
    }, [shareId, checkPackageStatus, t]);
    
    // Initial load - check for URL password only
    useEffect(() => {
        if (pwd) {
            // If we have a password from URL, try it
            tryLoadWithPassword(pwd);
        } else {
            // No password in URL, show login form
            setShowPasswordForm(true);
        }
    }, [shareId, pwd, tryLoadWithPassword]); // tryLoadWithPassword needs to be in deps array

    // Handle password submission from password form
    const handlePasswordSubmit = useCallback((submittedPassword) => {
        setPasswordError(null);
        tryLoadWithPassword(submittedPassword);
    }, [tryLoadWithPassword]);

  
    
    const packImage = useCallback(() => {
        if (!password) return;
        
        setPackagingStatus('packaging');
        http.get(`/customer/share/${shareId}/pack`, {
            params: { password: password }
        })
        .then(response => {
            // Start polling for status after initiating the pack
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
            }
            pollingIntervalRef.current = setInterval(()=>{
                checkPackageStatus(password)
            }, 3000); // Poll every 3 seconds
        })
        .catch(error => {
            setPackagingStatus('failed');
            snackbarStore.showSnackbar(t("Pack images failed"), "error");
        });
    }, [shareId, snackbarStore, t, checkPackageStatus, password]);
    
    // Password gating
    if (showPasswordForm) {
        return <SharedFolderPassword 
                shareId={shareId} 
                pwd={pwd}
                onSubmitPassword={handlePasswordSubmit}
                error={passwordError}
                />
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1 }}>
                <PackageButton
                    packagingStatus={packagingStatus}
                    downloadUrl={downloadUrl}
                    packImage={packImage}
                />
                
                {/* 添加保存到本地文件夹按钮 */}
                <SaveToLocalButton 
                    files={files}
                    currentFolder={currentFolder}
                    password={password}
                    shareId={shareId}
                />
                
                {currentFolder !== rootFolderId && (
                    <IconButton
                        onClick={() => {
                            setCurrentFolder(parentFolder);
                        }}
                    >
                        <ReplyIcon />
                    </IconButton>
                )}
            </Box>
            
            <Box sx={{
                paddingBottom: "50px",
                overflowY: "scroll",
                flex: 1,
                m: {
                    xs: 1,
                },
            }}>{files.length > 0 ? (
                    <Grid container spacing={2}>
                        {files.filter(
                            (file) => file.parentId === currentFolder
                        ).map((file, index) => (
                            <Grid item xs={4} sm={3} md={2} lg={1} key={index}>
                                {file.isFolder ? (
                                    <Box sx={{
                                        height:"100%",
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <FolderIcon 
                                            sx={{
                                                fontSize:"120px",
                                                color:"#f7d577",
                                                flex:1,
                                                maxHeight:" 120px",
                                                width: "100%",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setParentFolder(currentFolder)
                                                setCurrentFolder(file.id)
                                            }}
                                        />
                                        <Typography sx={{ textAlign: "center" }}>{file.name}</Typography>
                                    </Box>
                                    ): <Box
                                        sx={{
                                        position: "relative",
                                        height: 0,
                                        paddingTop: '133%',
                                        backgroundImage: `url(${file.urlSmall})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: 'cover', // 确保图片覆盖整个元素
                                        backgroundPosition: 'center', // 图片居中
                                        cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            let previewFiles = files.filter((f) => !f.isFolder && f.parentId === currentFolder)
                                            setPreviewFiles(previewFiles)
                                            setCurrentPreviewIndex(previewFiles.findIndex((f) => f.id === file.id))
                                        }}
                                    ></Box>
                                }
                            </Grid>
                        ))}
                    </Grid>
                ) :<Empty message={t("No picture available.")} /> }
            </Box>
            <FilePreviewDialog 
                open={currentPreviewIndex >= 0}
                setOpen={() => {
                    setCurrentPreviewIndex(-1)
                }}
                setCurrentPreviewIndex={setCurrentPreviewIndex}
                currentPreviewIndex={currentPreviewIndex}
                previewFiles={previewFiles}
            />
        </Box>
    );
}));

export default SharedFolder;
