import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Previewer = ({ url, title, visible, onClose }) => {
    const [loading, setLoading] = React.useState(true);
    const [currentUrl, setCurrentUrl] = React.useState(null);
    useEffect(() => {
        setCurrentUrl(url);
        return ()=>{
            url = null;
            setLoading(true);
        };
    }, [url])
    return (
          <Dialog
              open={!!visible}
              keepMounted
              onClose={onClose}
              fullWidth={true}
              maxWidth="xl"
            >
                <DialogTitle sx={{ position: 'relative' }}>
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {loading && <span>Loading...</span>}
                        {currentUrl && <img 
                            src={currentUrl}
                            onLoad={() => {
                                setLoading(false)
                            }}
                            alt=""
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: "auto",
                                display: loading?"none":"block",
                            }}
                        />}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
       
    );
};

export default Previewer;