import { Box, Button, Grid, Typography, Menu, MenuItem, IconButton, LinearProgress } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import withAuth from '../../HOC/withAuth';
import { useTranslation } from 'react-i18next';
import http from '../../http';
import FolderIcon from '@mui/icons-material/Folder';
import './index.css';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumb from './component/breadcrumb';
import ReplyIcon from '@mui/icons-material/Reply';
import Empty from "../../components/Empty";
import Previewer from './component/previewer';
import Share from './component/share';
import ShareIcon from '@mui/icons-material/Share';
import UpgradeNotice from '../../components/UpgradeNotice';

const FilesList = inject( 'dialogStore','userStore','snackbarStore','storageStore')(observer(({  dialogStore,userStore,snackbarStore,storageStore }) => {

  const { t } = useTranslation();
  const navigator = useNavigate();
  const [contextMenu, setContextMenu] = useState(null);
  const [editingFile, setEditingFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    total: 0,
    current: 0,
    completed: 0,
    fileName: ''
  });

  let folderId = useParams().folderId;
  if (!folderId) {
    folderId = 0;
  }

  useEffect(() => {
    storageStore.setCurrentFolderId(folderId);
    storageStore.featchFiles();
  }, [folderId]);

  useEffect(() => {
    if (editingFile) {
      const input = document.querySelector(`[data-id="${editingFile}"] input`);
      input?.focus();
    }
  }, [editingFile]);

  const onSelectFile = useCallback( async (e) => {
    const files = e.target.files;
    const validFileTypes = ['image/jpeg', 'image/jpg'];
    if (!files || files.length === 0) return;
    for (let file of files) {
      if (!validFileTypes.includes(file.type)) {
        snackbarStore.showSnackbar(t('Only JPG files are allowed'), 'error');
        return;
      }
    }
    if (files && files.length > 0) {
      setUploading(true);
      setUploadProgress({
        total: files.length,
        current: 1,
        completed: 0,
        fileName: files[0].name
      });

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        setUploadProgress(prev => ({
          ...prev,
          current: i + 1,
          fileName: file.name
        }));

        await storageStore.addFile(file);

        setUploadProgress(prev => ({
          ...prev,
          completed: prev.completed + 1
        }));
      }

      setUploading(false);
      snackbarStore.showSnackbar(t('All files uploaded successfully'), 'success');
    }
  }, []);

  const onClickCreateFolder = useCallback(() => {
    storageStore.createFolder("new folder");
  }, []);

  const onClickOpenFolder = useCallback((folderId) => {
    navigator(`/user/netstorage/${folderId}`);
  }, []);

  const handleContextMenu = (event) => {
    event.preventDefault();
    const element = document.elementFromPoint(event.clientX, event.clientY);
    const gridItem = element.closest('[data-id]');
    if (!gridItem) {
      return;
    }
    let fileId = gridItem.getAttribute('data-id');
    let file = storageStore.files.find(f => f.id === fileId);

    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
            file:file
        }
        : // repeated contextmenu when it is already open closes it with null.
          null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  //preview related
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const showPreview = (url,title) => {
    setPreviewTitle(title);
    setPreviewUrl(url);
    setIsPreviewVisible(true);
  };

  const handleClosePreview = () => {
    setIsPreviewVisible(false);
  };


  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", height: "100%", p:2  }}
      onContextMenu={handleContextMenu}
    >
      <UpgradeNotice 
        requiredLevel={1}
        message={t('Network storage requires a premium account. Please upgrade your plan to access this feature.')}
      />
      
      {userStore.level >= 1 ? (
        <>
          {uploading && (
            <Box sx={{ width: '100%', mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t("Uploading file")} {uploadProgress.current} / {uploadProgress.total}: {uploadProgress.fileName}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={(uploadProgress.completed / uploadProgress.total) * 100}
                sx={{ height: 8, borderRadius: 2 }}
              />
              <Typography variant="caption" display="block" sx={{ mt: 0.5, textAlign: 'right' }}>
                {uploadProgress.completed} / {uploadProgress.total} {t("files completed")}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              multiple={true}
              onChange={onSelectFile}
              disabled={storageStore.currentFolderId == "0"}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (storageStore.currentFolderId== "0") {
                    snackbarStore.showSnackbar(t("Please do not upload files in the root directory, create a subfolder first as only subfolders can be shared"), 'warning');
                  return;
                }else{
                  document.getElementById('fileInput').click();
                }
              }}
            >
              {t("Upload File")}
            </Button>
            {storageStore.parentFiles.length < 1 && (
              <Button variant="contained" color="primary" sx={{ ml: 2 }}
                onClick={onClickCreateFolder}
              >{t("create folder")}</Button>
            )}
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            mt: 2,
          }}>
            <IconButton  color="primary" sx={{ fontSize: "12px",marginRight:"10px" }}
              onClick={() => {
                if (storageStore.currentFolderId === 0) {
                  return;
                }
                let parentId = storageStore.currentFile?.parentId;
                if (parentId != undefined) {
                  navigator(`/user/netstorage/${parentId}`);
                }
              }}
            >
              <ReplyIcon />
            </IconButton>
            <Breadcrumb paths={storageStore.parentFiles} currentFile={storageStore.currentFile} />
          </Box>
          <Grid container spacing={2} sx={{paddingTop:"20px"}}   >
            {storageStore.files.length === 0 && (
              <Empty message={t("No files")} />
            )}
            {storageStore.files.map((file) => {
              return (
                <Grid item sm={4} md={3} lg={2} data-id={file.id} key={file.id} >
                  <Box className='folderItem'
                    onClick={() => {
                      if (file.isFolder && !editingFile) {
                        onClickOpenFolder(file.id);
                      }
                      if(!file.isFolder){
                        showPreview(file.url,file.name);
                      }
                    }}
                  >
                    {(!file.isFolder && file.progress < 100) &&<div className="progress">
                      <span>{file.progress}%</span>
                    </div>}
                    {file.isFolder? <Box sx={{
                      position:"relative"
                    }}>
                      <FolderIcon className='icon' />
                      {
                        file?.Shares.length>0 && <p className='shared_wrapper'>
                          <span className='shared_text'>{t("Shared")}</span>
                          <ShareIcon className="shared_icon" />
                        </p>
                      }
                    </Box>
                     :  <img src={file.urlSmall} className='icon' />}
                    {editingFile === file.id ? (
                      <input
                        type="text"
                        value={file.name}
                        onChange={(e) => {
                          file.name = e.target.value;
                        }}
                        onBlur={() => {
                          storageStore.rename(file.id, file.name);
                          setEditingFile(null);
                        }}
                      />
                    ) : (
                      <Typography className='text'>{file.name}</Typography>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Previewer
            title={previewTitle}
            url={previewUrl}
            visible={isPreviewVisible}
            onClose={handleClosePreview}
          />
          <Share />

          <Menu
            open={contextMenu !== null}
            onClose={handleCloseContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            {contextMenu?.file.isFolder && <MenuItem
              onClick={() => {
                const file = contextMenu.file;
                if (file) {
                  storageStore.setCurrentShareFolder(file)
                }
                handleCloseContextMenu();
              }}
            >
              {t("Share To Customer")}
            </MenuItem>}

            <MenuItem
              onClick={() => {
                const file = contextMenu.file;
                if (file) {
                  setEditingFile(file.id);
                }
                handleCloseContextMenu();
              }}
            >
              {t("Rename")}
            </MenuItem>

            <MenuItem
              onClick={() => {
                const file = contextMenu.file;
                if (file) {
                  storageStore.deleteFile(file.id);
                }
                handleCloseContextMenu();
              }}
            >
              {t("Delete")}
            </MenuItem>

            <MenuItem
              disabled={storageStore.currentFolderId === 0}
              onClick={() => {
                let parentId = storageStore.currentFile?.parentId;
                if (parentId != undefined) {
                  navigator(`/user/netstorage/${parentId}`);
                }
                handleCloseContextMenu();
              }}
            >
              {t("back to parent folder")}
            </MenuItem>
          </Menu>
        </>
      ) : null}
    </Box>
  );
}));

export default withAuth(FilesList);
