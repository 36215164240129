import { Box, Typography, Button } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getLevelName } from '../util';
import { inject, observer } from 'mobx-react';
import http from '../http';

const UpgradeNotice = inject('userStore', 'chargeStore')(observer(({
    userStore, 
    chargeStore,
    requiredLevel = 2,
    message,
    upgradeButtonText,
}) => {
    const { t } = useTranslation();


    if (userStore.level >= requiredLevel) return null;

    const openChargeDialog = () => {
        chargeStore.openChargeDialog()
    }

    // Use provided message or default message
    const noticeMessage = message || t('You are currently subscribed to the {{plan}}, which offers images at a resolution of {{resolution}}. You have the option to upgrade to the {{nexPlan}} for higher quality images.', {
        plan: t(getLevelName(userStore.level)),
        resolution: userStore.maxDimension,
        nexPlan: t(getLevelName(userStore.level + 1)),
    });

    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 2,
                bgcolor: '#fff3e0', // Soft warning color
                borderRadius: 1,
                mb: 2
            }}
        >
            <Typography sx={{ flex: 1 }}>{noticeMessage}</Typography>
            <Button 
                variant='text' 
                sx={{ fontSize: "0.75rem", height: "20px" }} 
                onClick={openChargeDialog}
            >
                {upgradeButtonText || t('Upgrade')}
            </Button>
        </Box>
    );
}));

export default UpgradeNotice;
